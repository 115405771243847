/* You can add global styles to this file, and also import other style files */
$fa-font-path: "~font-awesome/fonts" !default;

html,
body {
    height: 99%;
}

body {
    margin: 0;
    background-color: #FFFFFF;
    font-family: 'Source Sans 3', sans-serif;
}

@media(min-width: 1399px) {
    .header img {
        height: 100px !important;
        width: 100px !important;
    }
}

@media (max-width: 1025px) {
    .landing-page {
        width: 64% !important;
    }

    .header img {
        height: 120px !important;
        width: 120px !important;
    }

    .working-process {
        padding: 3rem !important;
        width: unset !important;
    }

    .section-title {
        width: 100% !important;
    }

    .section-info {
        padding-left: unset !important;
        padding-right: 3rem !important;
    }

    .cards {
        width: 36% !important;
        padding: 24px !important;
        height: unset !important;
    }

    .accordian {
        padding: 2rem !important;
    }

    .footer {
        padding: 0 3rem 2rem !important;
    }

    .chat-screen .chat-messages-wrapper {
        width: 80% !important;
    }

    .chat-screen .input-container .chat-input {
        width: 74% !important;
        margin-left: 5% !important;
    }

    .align-bot {
        width: 76% !important;
    }

    .suggestion-chips {
        height: unset !important;
        padding: 16px 86px !important;
    }

    .header img {
        width: 80px !important;
        height: 80px !important;
    }

    .typing {
        width: 4% !important;
        margin-left: 2% !important;

        .dot {
            height: 1.2vh !important;
        }
    }

    .modal-dialog {
        width: 92% !important;
    }

}

@media (max-width: 769px) {
    .typing {
        width: 5% !important;
        margin-left: unset !important;
    }

    .header img {
        width: 80px !important;
        height: 80px !important;
    }
}

/* Extra Small Devices (Phones) */
@media (max-width: 600px) {
    img {
        height: 5% !important;
        width: 5% !important;
    }

    .header {
        font-size: 16px !important;
    }

    .landing-page {
        font-size: 28px !important;
        width: 90% !important;
    }

    .button {
        font-size: 20px !important;
    }

    .working-process {
        padding: 1rem 0 1rem 1rem !important;
        width: 90% !important;
    }

    .section-title {
        font-size: 28px !important;
        width: 77% !important;

        span {
            padding: 0 12px !important;
        }
    }

    .section-info {
        padding-left: 1rem !important;
    }

    .card-holder {
        padding: 0 1rem !important;
    }

    .cards {
        width: 36% !important;
        padding: 0 16px !important;
        font-size: 18px !important;
    }

    .card-icons {
        width: 32% !important;
        height: 32% !important;
    }

    .accordian {
        padding: 1rem !important;
    }

    .accordian-title {
        font-size: 24px !important;

        img {
            width: 8% !important;
        }

        span {
            span:first-child {
                font-size: 32px !important;
            }
        }

        &+span {
            font-size: 16px !important;
        }
    }

    .footer {
        padding: 1.6rem !important;
    }

    .chat-screen .input-container {
        width: 100% !important;
    }

    .chat-screen .input-container .chat-input {
        margin-left: 5% !important;
        width: 100% !important;
    }

    .chat-messages {
        // width: 64% !important;
    }

    .chat-screen .chat-messages-wrapper {
        width: 94% !important;
    }

    .message-content {
        padding: 16px !important;
        font-size: 16px !important;
        line-height: 18px !important;
    }

    .suggestion-chips {
        padding: 12px 16% !important;
        line-height: 14px !important;
        height: unset !important;
    }

    .align-bot {
        width: 72% !important;
    }

    .typing {
        width: 4% !important;
        margin-left: 5% !important;
    }
}

@media(max-width: 426px) {
    img {
        height: 10% !important;
        width: 10% !important;
    }

    .header {
        padding: 0 1rem !important;

        img {
            width: 80px !important;
            height: 80px !important;
        }
    }

    .send {
        right: 44px !important;
        top: 0 !important;

        img {
            height: 40px !important;
            width: 40px !important;
        }
    }

    .additional-info {
        font-size: 12px !important;
    }

    .message-content {
        font-size: 14px !important;
        line-height: 16px !important;

        &.user {
            align-self: normal !important;
            align-items: normal !important;
            input{
                max-width: 60% !important;
            }
        }

        .option-style {
            font-size: 12px !important;
        }

        .checklist-icon {
            width: 20px !important;
            height: 14px !important;
            padding: 3px !important;
        }
    }

    .edit-response {
        align-items: flex-end !important;
        img {
            width: 12px !important;
            height: 12px !important;
        }
    }

    .format-editable-div,
    .angular-editor-wrapper {
        li {
            font-size: 14px !important;
            line-height: 16px !important;
        }
    }

    .section {
        flex-direction: column !important;
    }

    .section-title {
        font-size: 24px !important;
        width: unset !important;
    }

    .cards {
        width: 80% !important;
        padding: 0 12px !important;
        font-size: 16px !important;
        height: unset !important;
    }

    .section-info {
        text-align: center !important;
    }

    .accordian-title {
        font-size: 20px !important;

        span {
            span:first-child {
                font-size: 20px !important;
            }
        }
    }

    .footer {
        text-align: center !important;
        padding-bottom: 1rem !important;
    }

    .chat-input {
        font-size: 12px !important;
        padding: 1rem !important;
    }

    img.edit {
        width: 10px !important;
    }

    .suggestion-chips {
        font-size: 12px !important;
        height: unset !important;
    }

    .align-bot {
        width: 84% !important;
    }

    .message-sender,
    .timestamp {
        font-size: x-small !important;
    }

    .chat-screen {
        padding: 0.5rem !important;
    }

    .typing {
        width: 6% !important;
    }

    .overlay {
        bottom: 50% !important;
    }

}

@media (max-width: 376px) {
    .typing {
        width: 9% !important;
        margin-left: 3% !important;
    }

    img {
        height: 10% !important;
        width: 12% !important;
    }

    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        white-space: pre-line;
        position: relative;
        top: -8px;
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        white-space: pre-line;
        position: relative;
        top: -8px;
    }

    :-ms-input-placeholder {
        /* IE 10+ */
        white-space: pre-line;
        position: relative;
        top: -8px;
    }

    :-moz-placeholder {
        /* Firefox 18- */
        white-space: pre-line;
        position: relative;
        top: -8px;
    }
}